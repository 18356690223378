<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form @submit.prevent="tooltipForm" autocomplete="off">
      <div class="row">
        <div class="col-12">
          <b-overlay
            :show="overlayFlag"
            :spinner-variant="this.$overlayVariant"
            :spinner-type="this.$overlayType"
            :rounded="this.$overlayRounded"
          >
            <div class="card">
              <div class="card-body">
                <p class="card-title-desc"></p>
                <div class="row">
                  <div class="col-6 col-sm-3 col-md-3">
                    <div class="mb-3 position-relative">
                      <label>ชื่อลูกค้า</label>
                      <multiselect
                        v-model="nameCus"
                        label="nameTh"
                        :options="rowsUser"
                        :show-labels="false"
                        :custom-label="customLabel"
                        open-direction="bottom"
                        @search-change="getDataCus"
                        @remove="getRemoveSelected"
                        placeholder=""
                        :class="{
                          'is-invalid': submitform && $v.nameCus.$error,
                        }"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span>
                      </multiselect>
                      <div
                        v-if="submitform && $v.nameCus.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.nameCus.required"
                          >Please Selected Data.</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-3 col-md-3">
                    <div class="mb-3 position-relative">
                      <code>* </code><label for="validation">ทะเบียน</label>
                      <multiselect
                        v-model="vin"
                        label="licensePlate"
                        type="search"
                        :options="rowsDataService"
                        :show-labels="false"
                        open-direction="bottom"
                        @search-change="getDataService"
                        placeholder=""
                        class="helo"
                        default
                        :class="{
                          'is-invalid': submitform && $v.vin.$error,
                        }"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span>
                      </multiselect>
                      <div
                        v-if="submitform && $v.vin.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.vin.required"
                          >Please Selected Data.</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-3 col-md-3">
                    <div class="mb-3 position-relative">
                      <code>* </code><label for="validation">เลขตัวถัง</label>
                      <multiselect
                        v-model="vin"
                        label="vin"
                        :options="rowsDataService"
                        :show-labels="false"
                        :close-on-select="true"
                        :internal-search="true"
                        open-direction="bottom"
                        @search-change="getDataService"
                        placeholder=""
                        :class="{
                          'is-invalid': submitform && $v.vin.$error,
                        }"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span>
                      </multiselect>
                      <div
                        v-if="submitform && $v.vin.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.vin.required"
                          >Please Selected Data.</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-3 col-md-3">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="validation">วันที่เข้ารับบริการ</label>
                      <date-picker
                        v-model="svDate"
                        format="YYYY-MM-DD"
                        value-type="format"
                      ></date-picker>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6 col-sm-3 col-md-3">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="validation">ประเภทการเข้ารับบริการ</label>
                      <div>
                        <multiselect
                          v-model="distance"
                          label="nameTh"
                          :options="rowsMasterSD"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                          class="helo"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 col-sm-3 col-lg-3">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="validation">วันที่เสร็จสิ้น</label>
                      <date-picker
                        v-model="expectFinishDate"
                        format="YYYY-MM-DD"
                        value-type="format"
                      ></date-picker>
                    </div>
                  </div>

                  <div class="col-6 col-sm-2 col-lg-2">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="validation">เวลาเสร็จสิ้น</label>
                      <date-picker
                        v-model="expectFinishTime"
                        format="HH:mm"
                        value-type="format"
                        type="time"
                      >
                      </date-picker>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md">
                    <button class="btn btn-success float-end">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </b-overlay>
        </div>
        <!-- end card -->
      </div>
    </form>
  </Layout>
</template>

<script>
// import vue2Dropzone from "vue2-dropzone";
import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import useNetw from "@useNetw";

import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";

/**
 * Add-product component
 */
export default {
  page: {
    title: "Add Product",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // vueDropzone: vue2Dropzone,
    Multiselect,
    Layout,
    PageHeader,
    DatePicker,
    // DatePicker,
  },
  data() {
    return {
      urlPath: this.$route.params.url,
      userBranch: "",
      rowsUser: [],
      rowsService: [],
      rowsDataService: [],
      rowsMasterSD: [],
      licensePlate: "",
      vin: "",
      nameCus: "",
      hasBpJob: "",
      hasGsJob: "",
      hasWcJob: "",
      svDate: "",
      submitform: false,
      distance: "",
      expectFinishDate: "",
      expectFinishTime: "",

      items: [
        {
          text: "บริการ",
          active: true,
        },
        {
          text: "การเข้ารับบริการ",
          active: false,
          href: "/servicelist",
        },
        {
          text: "แก้ไขข้อมูลการเข้ารับบริการ",
          active: true,
        },
      ],
      title: "แก้ไขข้อมูลการเข้ารับบริการ",
      form: {
        fname: "",
        lname: "",
        city: "",
        state: "",
        zipcode: "",
      },
      range: {
        minlen: "",
        maxlength: "",
        between: "",
        minval: "",
        maxval: "",
        rangeval: "",
        expr: "",
      },
      typeform: {
        name: "",
        password: "",
        confirmPassword: "",
        email: "",
        url: "",
        digit: "",
        number: "",
        alphanum: "",
        textarea: "",
      },
      rows: [],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: {
          "My-Awesome-Header": "header value",
        },
      },
      value1: null,
      options: [
        "High Quality",
        "Leather",
        "Notifications",
        "Sizes",
        "Different Color",
      ],
      overlayFlag: false,
    };
  },
  validations: {
    form: {
      fname: {
        required,
      },
      lname: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
      zipcode: {
        required,
      },
    },
    vin: {
      required,
    },
    nameCus: {
      required,
    },

    range: {
      minlen: {
        required,
        minLength: minLength(6),
      },
      maxlength: {
        required,
        maxLength: maxLength(6),
      },
      between: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(10),
      },
      minval: {
        required,
        minValue: minValue(6),
      },
      maxval: {
        required,
        maxValue: maxValue(6),
      },
      rangeval: {
        required,
        minValue: minValue(6),
        maxValue: maxValue(100),
      },
      expr: {
        required,
      },
    },
    typeform: {
      name: {
        required,
      },
      password: {
        required,
        minLength: minLength(6),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
      email: {
        required,
        email,
      },
      url: {
        required,
        url,
      },
      digit: {
        required,
        numeric,
      },
      number: {
        required,
        numeric,
      },
      alphanum: {
        required,
        alphaNum,
      },
      textarea: {
        required,
      },
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.userBranch = arrayBranch;
  },
  created() {
    this.getDetailService();
    this.getDataServiceDistances();
  },
  methods: {
    customLabel({ nameTh, familyNameTh }) {
      return `${nameTh}  ${familyNameTh}`;
    },
    getDataServiceDistances: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/master/service-distances", {})
        .then((response) => {
          this.rowsMasterSD = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataService: function() {
      this.isLoading = true;
      useNetw
        .get("api/service/vehicle", {
          params: {
            page: 1,
            perPage: 100,
            // vin: this.vin.vin,
            branchId: this.userBranch,
          },
        })
        .then((response) => {
          this.rowsDataService = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    getDataCus: function() {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/service/customer", {
          params: {
            page: 1,
            perPage: 100,
            branchId: this.userBranch,
            nameTh: this.nameCus.nameTh,
          },
        })
        .then((response) => {
          this.rowsUser = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getRemoveSelected: function() {
      this.isLoading = true;
      useNetw
        .get("api/service/customer", {
          params: {
            page: 1,
            perPage: 100,
            branchId: this.userBranch,
          },
        })
        .then((response) => {
          this.rowsUser = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    submitUpdateServices: function() {
      useNetw
        .put("/api/service/update", {
          svId: atob(this.$route.params.id),
          cusId:
            this.nameCus.cusId.cusId != null
              ? this.nameCus
              : this.nameCus.cusId,
          vehicleId:
            this.vin.vehicleId.vehicleId != null
              ? this.vin
              : this.vin.vehicleId,
          svDate: this.svDate != null ? this.svDate : this.svDate,
          distanceId:
            this.distance.distanceId != null
              ? this.distance.distanceId
              : this.distance.distanceId,
          expectFinishDate:
            this.expectFinishDate != null
              ? this.expectFinishDate
              : this.expectFinishDate,
          expectFinishTime:
            this.expectFinishTime != null
              ? this.expectFinishTime
              : this.expectFinishTime,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          if (this.urlPath === "servicelist") {
            this.$router.push({ name: "servicelist" });
          } else {
            this.$router.push({ name: "servicegallery" });
          }
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },

    getDetailService: function() {
      this.overlayFlag = true;
      useNetw
        .get("/api/service/show", {
          params: {
            svId: atob(this.$route.params.id),
          },
        })
        .then((response) => {
          this.rowsService = response.data.data;
          this.nameCus = {
            cusId: response.data.data.cusId,
            nameTh: response.data.data.nameTh,
            familyNameTh: response.data.data.familyNameTh,
          };
          this.licensePlate = {
            vehicleId: response.data.data.vehicleId,
            vin: response.data.data.vin,
            licensePlate: response.data.data.licensePlate,
          };
          this.vin = {
            vehicleId: response.data.data.vehicleId,
            vin: response.data.data.vin,
            licensePlate: response.data.data.licensePlate,
          };
          this.distance = {
            distanceId: response.data.data.distanceId,
            nameTh: response.data.data.distanceNameTh,
          };
          this.svDate = response.data.data.svDate;
          this.expectFinishDate = response.data.data.expectFinishDate;
          this.expectFinishTime = response.data.data.expectFinishTime;

          const servicetime = new Date(`12/12/2564 ${this.expectFinishTime}`);
          this.expectFinishTime =
            ("0" + servicetime.getHours()).slice(-2) +
            ":" +
            ("0" + servicetime.getMinutes()).slice(-2);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.invalid != true) {
        this.submitUpdateServices();
      }
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },
  middleware: "authentication",
};
</script>
